import React, { useRef } from 'react';
import useReadingTime from 'use-reading-time';
import { Blockquote } from 'patterns/Blockquote';
import { Content } from 'patterns/Content';
import { Image } from 'patterns/Image';
import { MastheadArticle } from 'patterns/MastheadArticle';
import { Shell } from 'patterns/Shell';

import '../../scss/main.scss';

function ArticlePage2({
    location: {
        pathname,
    },
}) {
    const article = useRef();
    const { readingTime } = useReadingTime(article);

    return (
        <Shell
            pageDescription="Reflecting on 2020 – a year that included the most change in my life personally and professionally for a long time. "
            pageSocialImage=""
            pageTitle="2020 year in review"
            pathname={pathname}
        >
            <MastheadArticle
                authorImage={{
                    alt: '',
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: 'https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format'
                }}
                authorName="Rob Simpson"
                date="31 Dec, 2020"
                image={{
                    alt: '',
                    dimensions: {
                        height: 1024,
                        width: 768,
                    },
                    url: 'https://images.prismic.io/robsimpson/2f4a907b-376a-4174-8762-734975b18bd1_about-zoo.png?auto=compress,format'
                }}
                readTime={readingTime}
                title="2020 year in review"
            />
            <div className="container" ref={article}>
                <Content
                    content={[
                        {spans: [], text: "2020 is coming to a close, a year that many would rather forget, but for me, it’s a year worth reflecting on.", type: "paragraph"},
                        {spans: [], text: "I kicked the year off with plans and goals that I wanted to achieve and looking back, things got thrown in a completely different direction.", type: "paragraph"},
                        {spans: [], text: "Whilst I didn’t accomplish many of the goals I set out to achieve, I finally managed to achieve one of my biggest and longest-standing goals – which was to go freelance full-time.", type: "paragraph"},
                        {spans: [], text: "So here’s a look at my year, from a personal and business perspective.", type: "paragraph"},
                        {spans: [], text: "🧳 Business", type: "heading2"},
                        {spans: [], text: "At the start of the year, I didn’t imagine 12 months down the line I’d be working for myself.", type: "paragraph"},
                        {spans: [], text: "Well, I’m 4 months in and I’ve earned more money, whilst working less than I did being employed full-time.", type: "paragraph"},
                        {spans: [], text: "Some are frightened by the idea of not knowing how much they’ll earn each month – but I think it’s great. It means each month I have the potential to earn more than before.", type: "paragraph"},
                        {spans: [], text: "📬 No. work enquiries", type: "heading3"},
                        {spans: [], text: "I decided to go through my emails and CRM to find out the number of work enquires I received in 2020:", type: "paragraph"},
                        {spans: [], text: "👉 Serious enquiries: 30", type: "list-item"},
                        {spans: [], text: "👉 Dev only enquiries: 21", type: "list-item"},
                        {spans: [], text: "👉 Design only enquiries: 5", type: "list-item"},
                        {spans: [], text: "👉 Design & dev enquiries: 4", type: "list-item"},
                        {spans: [], text: "👉 Prismic enquiries: 16", type: "list-item"},
                        {spans: [], text: "👉 Gatsby enquiries: 15", type: "list-item"},
                        {spans: [], text: "👉 NextJS enquiries: 1", type: "list-item"},
                        {spans: [], text: "👉 Projects closed: 9", type: "list-item"},
                        {spans: [], text: "👉 Projects open for 2021: 9", type: "list-item"},
                        {spans: [], text: "I’d say I had a decent number of enquires throughout the year but I had to turn a lot of them down as I was employed full-time and didn’t have time to do them.", type: "paragraph"},
                        {spans: [], text: "👩🏻‍💼 Key clients", type: "heading3"},
                        {spans: [], text: "Looking back over the past 4 months, I’m shocked at the number of different people I’ve been able to work with and the different types of projects I’ve been able to work on.", type: "paragraph"},
                        {spans: [], text: "That being said here’s a look at some of the key projects I’ve worked on in 2020:", type: "paragraph"},
                        {spans: [], text: "🫐 Pacific Produce", type: "heading4"},
                        {spans: [], text: "👍 Figma", type: "list-item"},
                    ]}
                />
                <Image
                    image={{
                        alt: '',
                        dimensions: {
                            height: 1288,
                            width: 1030,
                        },
                        url: 'https://images.prismic.io/robsimpson/707ea300-ac7b-4997-a89e-250cebb2eeaa_frame-12.jpeg?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🚜 Oxbury", type: "heading4"},
                        {spans: [], text: "👍 Gatsby", type: "list-item"},
                        {spans: [], text: "👍 Prismic", type: "list-item"},
                        {spans: [], text: "👍 Zen Sell", type: "list-item"},
                        {spans: [], text: "👍 AWS", type: "list-item"},
                        {spans: [], text: "👍 Bitbucket Pipelines", type: "list-item"},
                        {spans: [], text: "👍 Storybook", type: "list-item"},
                    ]}
                />
                <Image
                    image={{
                        alt: '',
                        dimensions: {
                            height: 1288,
                            width: 1030,
                        },
                        url: 'https://images.prismic.io/robsimpson/2201181b-e5fa-4ac7-919f-fd428b181313_frame-11.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "💨 Dashboard", type: "heading4"},
                        {spans: [], text: "👍 Gatsby", type: "list-item"},
                        {spans: [], text: "👍 Prismic", type: "list-item"},
                        {spans: [], text: "👍 Mailchimp", type: "list-item"},
                        {spans: [], text: "👍 Netlify", type: "list-item"},
                        {spans: [], text: "👍 Storybook", type: "list-item"},
                    ]}
                />
                <Image
                    image={{
                        alt: '',
                        dimensions: {
                            height: 1288,
                            width: 1030,
                        },
                        url: 'https://images.prismic.io/robsimpson/e4959bd9-8902-4f48-8330-a8a797fcf859_frame-9.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🏭 Kinta AI", type: "heading4"},
                        {spans: [], text: "👍 Figma", type: "list-item"},
                        {spans: [], text: "👍 Gatsby", type: "list-item"},
                        {spans: [], text: "👍 Prismic", type: "list-item"},
                        {spans: [], text: "👍 Mailchimp", type: "list-item"},
                        {spans: [], text: "👍 Netlify", type: "list-item"},
                        {spans: [], text: "👍 Storybook", type: "list-item"},
                    ]}
                />
                <Image
                    image={{
                        alt: '',
                        dimensions: {
                            height: 1288,
                            width: 1030,
                        },
                        url: 'https://images.prismic.io/robsimpson/cc6a36f8-cc9a-4146-9525-3c74fb7ff4e5_frame-10.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "💬 Client testimonials", type: "heading3"},
                        {spans: [], text: "One of the nicest things (which I wasn’t expecting to be a big deal) are the nice things my clients have said about working with me:", type: "paragraph"},
                    ]}
                />
                <Blockquote
                    authorName="David Laub and Rohit Das: Co-Founders at Howl"
                    quote="Rob has a unique ability to understand user experience needs, produce aesthetically pleasing designs, and write maintainable/modular code. To anyone in search of a highly creative and experienced developer for their next project - look no further"
                />
                <Blockquote
                    authorName="Bryon Lowen: Programme Manager at Dashboard"
                    quote="We are delighted with the outcome. It is a step-change from our previous website, and we can highly recommend Rob for his approach/web technology expertise / and all-around delivery"
                />
                <Blockquote
                    authorName="Max Feider: VP Operations at Kinta AI"
                    quote="We love Rob’s redesign of our website. He did an awesome job gathering our thoughts and requests in a structured and efficient process, came up with creative ideas and solutions and delivered a great end-product on a tight deadline. Well done!"
                />
                <Content
                    content={[
                        {spans: [], text: "🙋🏻‍♂️ Personal", type: "heading2"},
                        {spans: [], text: "I’ve been locked up at home since March… so, I’d say 2020 has been somewhat uneventful compared to other years.", type: "paragraph"},
                        {spans: [], text: "That being said, (outside of doing stuff) I feel like quite a lot has changed in my personal life compared to other years.", type: "paragraph"},
                        {spans: [], text: "🌱 Planting trees with Ecologi", type: "heading3"},
                        {spans: [{
                            start: 37,
                            end: 44,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://ecologi.com/",
                            },
                        }], text: "At the end of August, I signed up to Ecologi – a monthly subscription where they plant trees to offset your carbon footprint.", type: "paragraph"},
                        {spans: [], text: "Over the past 4 months, I’ve planted 160 trees, which equates to:", type: "paragraph"},
                        {spans: [], text: "🚗 28,978 miles driven in a car", type: "list-item"},
                        {spans: [], text: "🌊 35 metres2 of sea ice saved", type: "list-item"},
                        {spans: [], text: "🛩 9 long haul flights", type: "list-item"},
                        {spans: [{
                            start: 54,
                            end: 84,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://ecologi.com/simpsons?r=5f521bb5f7b2880018219050",
                            },
                        }], text: "If you feel the desire to sign up to Ecologi, you can subscribe using my signup link and Ecologi will plant an extra 30 trees for both of us.", type: "paragraph"},
                        {spans: [], text: "🚗 Cutting out travel miles", type: "heading3"},
                        {spans: [], text: "Like many, I’ve been working at home for most of the year. By working at home since March, I’ve cut out ~6,400 travel miles in my car, this also means I’ve saved a lot of money on fuel (an easy way to justify paying for Ecologi for me).", type: "paragraph"},
                        {spans: [], text: "🌳 Investing in environmentally friendly products", type: "heading3"},
                        {spans: [], text: "In the latter half of 2020, I decided to start making more conscious decisions about the products I buy.", type: "paragraph"},
                        {spans: [], text: "When looking to buy new products and swap out existing ones (when they’ve finished), I looked for things that are recyclable, little to no plastic and vegan.", type: "paragraph"},
                        {spans: [], text: "These are little changes, but like most things, small changes over a long period make a big difference.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Environmentally friendly products – tea, washing machines capsules and vitamins"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 804,
                            width: 1206,
                        },
                        url: 'https://images.prismic.io/robsimpson/d9f1e421-5f23-42bd-9870-aafa525094ac_eco-products.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🌮 Dietary change", type: "heading3"},
                        {spans: [], text: "I wouldn’t say my diet has changed a lot in 2020, but my perspective has. The biggest change we can all make to reduce our impact on the environment is to adopt more of a plant-based diet.", type: "paragraph"},
                        {spans: [], text: "In priors years, I’ve tried going vegan on several occasions with no prevail. Well, 2020 is no different, I’m not vegan and I don’t think I’ll ever be. But I’ve embraced vegetarian and vegan eating into my life.", type: "paragraph"},
                        {spans: [], text: "Over the years I’ve massively reduced the amount of meat I eat and now I’m trying to add more plant-based meals into my diet – all of this has been a process to me and I think it’ll continue to be that way.", type: "paragraph"},
                        {spans: [], text: "🍏 Investing in my health", type: "heading3"},
                        {spans: [], text: "For the past ~9 years I’ve considered myself to be a ”gym-goer”. I felt like I was someone who worked out quite a lot but in reality, I’d be lucky to hit 3 workouts a week.", type: "paragraph"},
                        {spans: [], text: "My health has and always will be very important to me, but as someone who sits for most of the day, I couldn’t accept less than 3 hours of exercise a week to be acceptable.", type: "paragraph"},
                        {spans: [], text: "So in the latter half of the year, I upped my exercise game.", type: "paragraph"},
                        {spans: [], text: "🏋️ Turning my garage into a gym", type: "heading3"},
                        {spans: [], text: "At the start of the year, I came up with a plan to turn my garage into a gym. But when Covid hit and we were all stuck at home, that plan became more important than ever.", type: "paragraph"},
                        {spans: [], text: "To help fund this makeover, I decided I’d take on some freelance projects on the side to cover the cost (which became a massive help in taking that step to full-time freelance).", type: "paragraph"},
                        {spans: [{
                            start: 228,
                            end: 256,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://centr.com/join-us",
                            },
                        }], text: "It was only in the latter part of the year, that I was able to get back into the swing of things. Kev Simpson and I have been training almost 5 days a week – 3 days of weight training and 2 days of bodyweight training using the Centr app by Chris Hemsworth.", type: "paragraph"},
                        {spans: [], text: "I’ve never been a big fan of bodyweight workouts, but the combination of heavy lifting and endurance, bodyweight workouts are paying dividends in building more of a healthy body.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Garage gym transformation – before / after"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 807,
                            width: 1210,
                        },
                        url: 'https://images.prismic.io/robsimpson/9ab33bab-be87-4700-8f88-bb8d0b751318_gym.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "💊 Buying better quality supplements", type: "heading3"},
                        {spans: [{
                            start: 137,
                            end: 169,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.vivolife.co.uk/products/perform",
                            },
                        }], text: "Until 2020 the only supplement I took was protein powder. Over the years I’ve tried many, but towards the end of this year, I changed to Vivo life’s vegan protein powder.", type: "paragraph"},
                        {spans: [], text: "As I mentioned before, I wanted to buy products that were better for the environment and a vegan protein powder is better for that then whey.", type: "paragraph"},
                        {spans: [], text: "I also wanted a product that used high-quality ingredients, tasted good and didn’t have a grainy texture (like some other vegan protein powders I’ve tried before).", type: "paragraph"},
                        {spans: [{
                            start: 39,
                            end: 61,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://wearefeel.com/products/essential-multivitamin",
                            },
                        },{
                            start: 66,
                            end: 73,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://wearefeel.com/products/feel-algae-omega-3",
                            },
                        }], text: "Besides protein powder, I also started taking a multi-vitamin and omega 3 supplements from Feel. Again, they’re made from high-quality ingredients and are vegan.", type: "paragraph"},
                    ]}
                />
                <Image
                    caption="Vivo life vegan protein powder and creatine"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 804,
                            width: 1206,
                        },
                        url: 'https://images.prismic.io/robsimpson/a5d11ef3-fc0c-4479-9d06-d027623be9e9_vivo-products.webp?auto=compress,format'
                    }}
                />
                <Content
                    content={[
                        {spans: [], text: "🦻 Listened to fewer audiobooks", type: "heading3"},
                        {spans: [], text: "At the beginning of the year, I set a goal to read/listen to 30 books – that did not happen.", type: "paragraph"},
                        {spans: [], text: "I’ve been a big fan of audiobooks for several years now, however this year I decided to cancel my Audible subscription.", type: "paragraph"},
                        {spans: [], text: "It’s not because it sucks or that listening to books is bad, the problem was, now that I no longer had to drive to and from work (~40 min drive each way). I lost my opportunity to listen to audiobooks and since, I’ve not found a place in my routine where it works.", type: "paragraph"},
                        {spans: [], text: "Also, now that I’ve had time to reflect, the act of reading 30 books isn’t as useful as it sounds. Reading a great book, but not actioning anything that you learnt in it, leaves a lot of value on the table.", type: "paragraph"},
                        {spans: [], text: "And this is currently where I’m at in the crossroads. My plan for 2021 is to consume far fewer books, but actually, take action on them. Right now I don’t know what my plan for doing that looks like – but I’ll share my ideas when I know.", type: "paragraph"},
                        {spans: [], text: "💭 Closing thoughts", type: "heading2"},
                        {spans: [], text: "All things considered, my 2020 looks like it’s been a success. But like everyone, I also went through ups and downs and it took me a long time to find my feet through all of this.", type: "paragraph"},
                        {spans: [], text: "Looking back to March when we went into lockdown, I feel like I was going through the motions, waking up, working, doing not much else in the evening, going to sleep and repeat.", type: "paragraph"},
                        {spans: [], text: "I’d say a lot of my positive outcomes of 2020 happened in the latter part of the year.", type: "paragraph"},
                        {spans: [], text: "I’m excited for the new year, I have big plans for the year and look forward to sharing them with you soon – so stay tuned for a lot more updates.", type: "paragraph"},
                    ]}
                />
            </div>
        </Shell>
    );
}

export default ArticlePage2;
