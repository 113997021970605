import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';
import { Emoji } from 'components/Emoji';
import { Grid } from 'components/Grid';
import { Link } from 'components/Link';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './Blockquote.module.scss';

// const propTypes = {
//     authorName: '',
//     authorUrl: '',
//     buttonText: 'Share this on Twitter',
//     buttonUrl: '',
//     className: '',
//     gridClassName: '',
//     quote: '',
// };

// const defaultProps = {
//     authorName: PropTypes.string,
//     authorUrl: PropTypes.string,
//     buttonText: PropTypes.string,
//     buttonUrl: PropTypes.string,
//     className: PropTypes.string,
//     gridClassName: PropTypes.string,
//     quote: PropTypes.string,
// };

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['blockquote'],
    );

    return classes || undefined;
}

function Blockquote({
    authorName,
    authorUrl,
    buttonText,
    buttonUrl,
    className,
    gridClassName,
    quote,
}) {
    const classes = getClasses({ className });

    return (
        <div className={classes}>
            <Grid className={gridClassName}>
                <blockquote className={styles['container']}>
                    <div className={styles['wrapper']}>
                        <Emoji className={styles['emoji']} emoji="💭" />
                        {quote && (
                            <Text as="p" className={styles['quote']} emphasis="medium" size={6}>{quote}</Text>
                        )}
                        {authorName && (
                            <footer className={styles['footer']}>
                                <p className={styles['author']}>
                                    {(!authorUrl?.url && !authorUrl?.uid) && (
                                        <Text as="span" className={styles['authorName']} emphasis="low" size={1}>{authorName}</Text>
                                    )}
                                    {(authorUrl?.url || authorUrl?.uid) && (
                                        <span className={styles['authorName']}>
                                            <Link className={styles['authorLink']} emphasis="medium" size={1} reverseUnderline={true} to={authorUrl}>{authorName}</Link>
                                        </span>
                                    )}
                                </p>
                            </footer>
                        )}
                        {(buttonText && buttonUrl) && (
                            <Button className={styles['button']} color="secondary" to={`http://twitter.com/share?text=${quote}&url=https://robsimpson.digital${buttonUrl}`}>{buttonText}</Button>
                        )}
                    </div>
                </blockquote>
            </Grid>
        </div>
    );
}

// Blockquote.propTypes = propTypes;
// Blockquote.defaultProps = defaultProps;

export { Blockquote };
