// extracted by mini-css-extract-plugin
export var masthead = "MastheadArticle-module--masthead--3EUbc";
export var imageContainer = "MastheadArticle-module--imageContainer--1BUqJ";
export var image = "MastheadArticle-module--image--37N-a";
export var contentContainer = "MastheadArticle-module--contentContainer--1loCy";
export var title = "MastheadArticle-module--title--chxZ2";
export var author = "MastheadArticle-module--author--1wRck";
export var name = "MastheadArticle-module--name--25Wg4";
export var metaList = "MastheadArticle-module--metaList--2EC4E";
export var metaItem = "MastheadArticle-module--metaItem--3P9jR";
export var show = "MastheadArticle-module--show--jwhWR";
export var showFadeInDown = "MastheadArticle-module--show-fade-in-down--JtlUT";
export var showFadeInUp = "MastheadArticle-module--show-fade-in-up--2o-o3";
export var hideFadeOut = "MastheadArticle-module--hide-fade-out---li8h";