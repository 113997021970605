import React from 'react';

import { Image as ImageComponent } from 'components/Image';
import { Grid } from 'components/Grid';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './Image.module.scss';

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['image'],
    );

    return classes || undefined;
}

function Image({
    caption,
    className,
    gridClassName,
    image,
    size,
}) {
    const classes = getClasses({ className });

    return (
        <div className={classes}>
            <Grid className={gridClassName}>
                <figure className={styles['container']}>
                    <ImageComponent
                        className={styles['imageElement']}
                        image={image}
                        size={size}
                    />
                    {caption && (
                        <Text as="figcaption" className={styles['caption']}emphasis="low" size={1}>{caption}</Text>
                    )}
                </figure>
            </Grid>
        </div>
    );
}

export { Image };
