import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../Text';
import { cx } from 'utility';
import * as styles from './Emoji.module.scss';

const propTypes = {
    className: PropTypes.string,
    emoji: PropTypes.string,
};

const defaultProps = {
    className: '',
    emoji: '',
};

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['emoji'],
    );

    return classes || undefined;
}

function Emoji({
    className,
    emoji,
}) {
    const classes = getClasses({ className });

    return (
        <Text as="div" className={classes} emphasis="high">{emoji}</Text>
    );
}

Emoji.propTypes = propTypes;
Emoji.defaultProps = defaultProps;

export { Emoji };
