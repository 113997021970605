// extracted by mini-css-extract-plugin
export var container = "Blockquote-module--container--2CCJ7";
export var wrapper = "Blockquote-module--wrapper--2kU13";
export var emoji = "Blockquote-module--emoji--2f-jn";
export var quote = "Blockquote-module--quote--1M-ya";
export var footer = "Blockquote-module--footer--3nhCj";
export var author = "Blockquote-module--author--8fJw6";
export var button = "Blockquote-module--button--yWCDf";
export var show = "Blockquote-module--show--3wphV";
export var showFadeInDown = "Blockquote-module--show-fade-in-down--1AMDF";
export var showFadeInUp = "Blockquote-module--show-fade-in-up--Ync9F";
export var hideFadeOut = "Blockquote-module--hide-fade-out--2Guni";