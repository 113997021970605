import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'components/Avatar';
import { Image } from 'components/Image';
import { Grid } from 'components/Grid';
import { Heading } from 'components/Heading';
import { Text } from 'components/Text';
import { cx, capitalizeFirstLetter } from 'utility';
import * as styles from './MastheadArticle.module.scss';

const propTypes = {
    authorName: PropTypes.string,
    authorImage: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    className: PropTypes.string,
    date: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    readTime: PropTypes.string,
    title: PropTypes.string,
};

const defaultProps = {
    authorName: '',
    authorImage: {},
    className: '',
    date: '',
    image: {},
    readTime: '0',
    title: '',
};

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['masthead'],
    );

    return classes || undefined;
}

function MastheadArticle({
    authorImage,
    authorName,
    className,
    date,
    image,
    readTime,
    title,
}) {
    const classes = getClasses({ className });
    const readTimePostFix = (readTime === 1) ? 'min to read' : 'mins to read';

    return (
        <div className={classes}>
            <Grid>
                {image.url && (
                    <div className={styles['imageContainer']}>
                        <Image
                            className={styles['image']}
                            image={image}
                            size="large"
                        />
                    </div>
                )}
                <div className={styles['contentContainer']}>
                    {title && (
                        <Heading
                            className={styles['title']}
                            size={9}
                        >
                            {title}
                        </Heading>
                    )}
                    {authorName && (
                        <div className={styles['author']}>
                            <Text as="span" className={styles['name']} emphasis="medium" size={2}>By {authorName}</Text>
                            <Avatar
                                className={styles['avatar']}
                                image={authorImage}
                                size="small"
                            />
                        </div>
                    )}
                    <ul className={styles['metaList']}>
                        <Text as="li" className={styles['metaItem']} emphasis="medium" size={1}>🗓 {date}</Text>
                        <Text as="li" className={styles['metaItem']} emphasis="medium" size={1}>📖 {readTime} {readTimePostFix}</Text>
                    </ul>
                </div>
            </Grid>
        </div>
    );
}

MastheadArticle.propTypes = propTypes;
MastheadArticle.defaultProps = defaultProps;

export { MastheadArticle };
